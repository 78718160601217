import React from 'react';

import { useAuth } from '@/providers';
import { paths } from '@/routes';
import { Button, Dropdown, MenuProps, Space } from 'antd';
import { Image } from '../Image';
import { NavLink } from 'react-router-dom';

import './styles.scss';
import { useFeatureFlag } from '@/services/featureFlags';

const items: MenuProps['items'] = [
  {
    label: (
      <NavLink className="sub-nav-link" to={paths.productTemplates}>
        Product templates
      </NavLink>
    ),
    key: 'product-templates-link'
  },
  {
    label: (
      <NavLink className="sub-nav-link" to={paths.attributes}>
        Attributes
      </NavLink>
    ),
    key: 'attributes-link'
  }
];

// TODO: render inline when we won't use feature flag
const ProductsPageLink = (
  <NavLink
    isActive={(_match, location) =>
      ['/products', '/product-templates', '/attributes'].some((path) =>
        location.pathname.startsWith(path)
      )
    }
    className="nav-link"
    to={paths.products}
  >
    Products
  </NavLink>
);

export const Header = () => {
  const { logout } = useAuth();
  const { data } = useFeatureFlag('product-templates-page');

  const hasProductTemplatesPage = data?.state;

  return (
    <div className="header">
      <div>
        <Image name="logo" type="png" />

        <Space size="middle">
          <NavLink
            className="nav-link"
            to={paths.root}
            isActive={(_match, location) =>
              ['/companies', '/recommendation/'].some((path) =>
                location.pathname.startsWith(path)
              )
            }
          >
            Company
          </NavLink>
          {hasProductTemplatesPage ? (
            // Dropdown is not working properly when ProductsPageLink is a separate component
            <Dropdown menu={{ items }}>{ProductsPageLink}</Dropdown>
          ) : (
            ProductsPageLink
          )}
          <NavLink className="nav-link" to={paths.users}>
            Users
          </NavLink>
          <NavLink className="nav-link" to={paths.expiring}>
            Expiring
          </NavLink>
          <NavLink className="nav-link" to={paths.transferAccount}>
            Transfer Account
          </NavLink>
          <NavLink className="nav-link" to={paths.comboFinancials}>
            Combo Financials
          </NavLink>
          <NavLink className="nav-link" to={paths.commissions}>
            Commissions
          </NavLink>
          <NavLink className="nav-link" to={paths.carriers}>
            Carriers
          </NavLink>
        </Space>
      </div>
      <Button type="link" onClick={() => logout()}>
        Logout
      </Button>
    </div>
  );
};
