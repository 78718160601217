import { api } from './api';

export const getAttributesTranslations = async (
  language = 'de'
): Promise<{
  getAttributeLabel: (code: string) => string;
  getAttributeDescription: (code: string) => string | undefined;
}> => {
  const attributes: Record<string, { name: string; description?: string }> =
    await api.get(`/public/attributes?language=${language}`);

  return {
    /**
     * Returns the code as a fallback
     */
    getAttributeLabel(code: string): string {
      const label = attributes[code]?.name;

      if (!label) {
        console.warn(`No attribute translation found for code: ${code}`);
      }

      return label ?? code;
    },

    getAttributeDescription(code: string): string | undefined {
      return attributes[code]?.description;
    }
  };
};

export interface AttributeDefinition {
  code: string;
  nameEN: string;
  nameDE: string;
  descriptionEN?: string;
  descriptionDE?: string;
}

export const getAttributesDefinitionsList = async (): Promise<
  AttributeDefinition[]
> =>
  Promise.resolve([
    {
      code: 'foo_attribute',
      nameEN: 'Foo en',
      nameDE: 'Foo de',
      descriptionEN: 'Foo description en lorem ipsum dolor sit amet',
      descriptionDE: 'Foo description de lorem ipsum dolor sit amet'
    },
    {
      code: 'bar_attribute',
      nameEN: 'Bar en',
      nameDE: 'Bar de',
      descriptionEN: 'Bar description en lorem ipsum dolor sit amet',
      descriptionDE: 'Bar description de lorem ipsum dolor sit amet'
    },
    {
      code: 'baz_attribute',
      nameEN: 'Baz en',
      nameDE: 'Baz de',
      descriptionEN: 'Baz description en lorem ipsum dolor sit amet',
      descriptionDE: 'Baz description de lorem ipsum dolor sit amet'
    },
    {
      code: 'qux_attribute',
      nameEN: 'Qux en',
      nameDE: 'Qux de',
      descriptionEN: 'Qux description en lorem ipsum dolor sit amet',
      descriptionDE: 'Qux description de lorem ipsum dolor sit amet'
    },
    {
      code: 'quux_attribute',
      nameEN: 'Quux en',
      nameDE: 'Quux de',
      descriptionEN: 'Quux description en lorem ipsum dolor sit amet',
      descriptionDE: 'Quux description de lorem ipsum dolor sit amet'
    }
  ]);
