import { FC, useState } from 'react';

import { Datepicker, Loader, Table } from '@/components';
import { Upload } from '@/components/Upload';
import { CategoryLabelMap } from '@/enums';
import { SendPOAModal } from '@/pages/CompanyOverview/SendPOAModal';
import { useAuth } from '@/providers';
import { paths } from '@/routes';
import {
  UseUserCompaniesEntry,
  UseUserCompaniesSelectEntry,
  useCompany,
  useDeleteCompany,
  useDeleteLocation,
  useDeletePayment,
  useListAdmins,
  useLocationHome,
  usePayments,
  useRemoveUserFromCompany,
  useUpdateCompany,
  useUserCompanies
} from '@/services';
import { useBankingReports } from '@/services/integrations';
import { Company, CustomerStatus } from '@/types/company';
import { Location, LocationHome } from '@/types/location';
import { Address } from '@/types/self-assessment';
import { User } from '@/types/user';
import {
  findMDs,
  findOwner,
  getUserFullname,
  hasMoreThanOneUser,
  isMD,
  isNotEmpty,
  isOwner,
  returns,
  useGoBack,
  useGoToPath,
  useItemDialog,
  useLocalStorage,
  useModal
} from '@/utils';
import { formatAssessmentAddress } from '@/utils/address';
import {
  CiOutlined,
  DeleteFilled,
  EditFilled,
  LeftCircleOutlined,
  MenuOutlined,
  PlusCircleOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Button, Dropdown, Menu, Popconfirm, Select, Tag, message } from 'antd';
import {
  __,
  concat,
  evolve,
  filter,
  groupBy,
  ifElse,
  includes,
  keys,
  map,
  pipe,
  pluck,
  prop
} from 'ramda';
import { Link, useParams } from 'react-router-dom';
import { AddCompany } from '../Dashboard/AddCompanyModal';
import { EditCompanyModal } from '../Dashboard/EditCompanyModal';
import { LocationOverview } from '../LocationOverview';
import BankingIntegrationModal from './BankingIntegrationModal';
import { CompanyUserModal } from './CompanyUserModal';
import { HeadquartersModal } from './HeadquartersModal';
import { LocationModal } from './LocationModal';
import { PaymentModal } from './PaymentModal';
import './styles.scss';

const { Option } = Select;

// fixme: add types
const groupInsurances = (location: any) =>
  evolve(
    {
      insurances: pipe(
        filter(pipe(prop('categoryId'), includes(__, keys(CategoryLabelMap)))),
        groupBy<any, any>(
          pipe(
            prop('categoryId'),
            ifElse(
              includes(__, location.essentialCategoryId),
              returns('essential'),
              returns('other')
            )
          )
        )
      )
    },
    location
  ) as LocationHome;

export const CompanyOverview = () => {
  const { companyId, locationId } = useParams<{
    companyId: string;
    locationId: string;
  }>();
  const { data, isLoading } = useCompany(companyId);
  const { data: locationData, isLoading: isLocationLoading } = useLocationHome(
    locationId,
    { enabled: Boolean(locationId), select: groupInsurances }
  );

  if (isLoading || !data) return <Loader />;

  if (locationId && isLocationLoading) return <Loader />;

  if (locationData)
    return (
      <LocationOverview
        data={{
          companyName: data?.name,
          userData: data?.users.find((user) => user.userTypes.MD === true),
          ...locationData
        }}
      />
    );

  return <CompanyOverviewPage data={data} />;
};

// fixme

const makeCompaniesOptions = pipe(
  pluck('company') as any,
  map<UseUserCompaniesEntry['company'], UseUserCompaniesSelectEntry>(
    ({ companyId, name }) => ({ value: companyId, label: name })
  )
);

const columns = (
  LocationActions: FC<{ data: Location }>,
  companyId: string,
  sortsAndFilters: { sorts: Record<string, any>; filters: Record<string, any> }
) => [
  {
    title: 'Location Name',
    dataIndex: 'name',
    sortOrder:
      sortsAndFilters.sorts.field === 'name'
        ? sortsAndFilters.sorts.order
        : null,
    sorter: (a: Location, b: Location) => a?.name?.localeCompare(b?.name),
    render: (name: string, { locationId }: LocationHome) => (
      <Link to={`/companies/${companyId}/${locationId}`}>{name}</Link>
    )
  },
  {
    title: 'Location Address',
    dataIndex: ['address'],
    render: (address: Address) => formatAssessmentAddress(address)
  },
  {
    title: 'Location Type',
    dataIndex: 'locationType'
  },
  {
    title: '',
    render: (__: Location, data: Location) => (
      <Dropdown overlay={<LocationActions data={data} />}>
        <MenuOutlined style={{ cursor: 'pointer' }} />
      </Dropdown>
    )
  }
];

const CompanyOverviewPage = ({ data }: { data: Company }) => {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const { user } = useAuth();
  const [popOverVisible, setPopOverVisible] = useState<string | boolean>(false);
  const { data: adminList, isLoading } = useListAdmins();
  const owner = findOwner(data);
  const MDs = findMDs(data);

  const goToOverview = useGoToPath(concat('/companies/'));
  const goToDashboard = useGoToPath(paths.root);
  const goBack = useGoBack();

  const { data: userCompanies } = useUserCompanies(owner?.auth0, {
    select: makeCompaniesOptions,
    enabled: !!owner
  });
  const { data: companyPayments = [], isLoading: arePaymentsLoading } =
    usePayments(data.companyId);

  const updateCompanyMutation = useUpdateCompany(data.companyId);
  const deleteCompanyMutation = useDeleteCompany(data.companyId);
  const deleteLocationMutation = useDeleteLocation();
  const deletePaymentMutation = useDeletePayment();
  const removeUserMutation = useRemoveUserFromCompany(data.companyId);
  const { data: bankingReports, isLoading: isBankingReportsLoading } =
    useBankingReports(data.companyId);
  const [sortsAndFilters, setSortsAndFilters] = useLocalStorage(
    'locationsSortAndFilters',
    {
      sorts: {},
      filters: {}
    }
  );
  const {
    item: selectedUser,
    openItemDialog,
    closeItemDialog,
    isOpen
  } = useItemDialog<User>();

  const {
    item: selectedLocationId,
    openItemDialog: openLocationModal,
    closeItemDialog: closeLocationModal,
    isOpen: isLocationModalOpened
  } = useItemDialog<Location>();

  const {
    item: selectedPaymentId,
    openItemDialog: openPaymentModal,
    closeItemDialog: closePaymentModal,
    isOpen: isPaymentModalOpened
  } = useItemDialog<string>();

  const {
    isOpen: isHeadquartersModalOpen,
    open: openHeadquartersModal,
    close: closeHeadquartersModal
  } = useModal();

  const {
    isOpen: isBankingIntegrationModalOpen,
    open: openBankingIntegrationModal,
    close: closeBankingIntegrationModal
  } = useModal();

  const {
    isOpen: isSendPoaModalOpen,
    open: openSendPOAModal,
    close: closeSendPOAModal
  } = useModal();

  const handleDelete = () =>
    deleteCompanyMutation.mutateAsync().then(() => goToDashboard(paths.root));

  const handleDeleteLocation = (id: string) =>
    deleteLocationMutation.mutate(id);
  const handleDeletePayment = (id: string) => deletePaymentMutation.mutate(id);

  const handleAssignClient = (accountManagerId: string) =>
    updateCompanyMutation
      .mutateAsync({ accountManagerId: accountManagerId })
      .then(() => {
        message.success('Company assigned!');
        setPopOverVisible(false);
      });

  const handleChangeCustomerStatus = (customerStatus: CustomerStatus) =>
    updateCompanyMutation
      .mutateAsync({ customerStatus: customerStatus })
      .then(() => message.success('Company status changed'));

  const handleChangeCompanyWonAt = (companyWonAt: number | undefined) =>
    updateCompanyMutation
      .mutateAsync({
        companyWonAt: companyWonAt ? new Date(companyWonAt).toISOString() : null
      })
      .then(() => message.success('Company Won At changed'));

  const handleRemoveUser = (userId: string) =>
    removeUserMutation.mutate(userId);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(data.companyId);
    message.success('Copied to clipboard');
  };

  const LocationActions = ({ data }: { data: Location }) => (
    <Menu
      items={[
        { key: 'edit', label: 'Edit', onClick: () => openLocationModal(data) },
        {
          key: 'delete',
          label: (
            <Popconfirm
              title="Are you sure to delete this location?"
              onConfirm={() => handleDeleteLocation(data.locationId)}
            >
              <div style={{ color: 'red' }}>Delete</div>
            </Popconfirm>
          )
        }
      ]}
    />
  );

  if (isLoading || isBankingReportsLoading || !adminList) return <Loader />;

  const newAdminList = adminList.map((admin) => {
    if (admin.auth0 === user.sub) {
      return {
        auth0: user.sub,
        email: user.email
      };
    } else {
      return admin;
    }
  });

  const finalAdminList = [
    newAdminList.find(({ auth0 }) => auth0 === user.sub),
    ...newAdminList.filter(({ auth0 }) => auth0 !== user.sub)
  ].filter(isNotEmpty);

  return (
    <div className="company-overview">
      <div className="company-overview-actions">
        <Button type="text" onClick={goBack}>
          <LeftCircleOutlined />
          Go back
        </Button>
        <AddCompany
          userId={owner?.auth0}
          onSuccess={({ companyId }: Company) => goToOverview(companyId)}
        />
      </div>
      <div className="company-overview-details">
        <div className="company-overview-details-header">
          <div className="user-details">
            <UserOutlined />
            <span>Owner - {owner ? getUserFullname(owner) : 'Unassigned'}</span>
            {owner && (
              <span>
                {owner?.email} | {owner?.phone}
              </span>
            )}
          </div>
          {userCompanies && userCompanies.length > 1 && (
            <Select
              style={{ minWidth: '200px' }}
              defaultValue={data.companyId}
              onChange={goToOverview}
            >
              {userCompanies.map(({ value, label }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          )}
        </div>
        <div className="company-name">
          <CiOutlined />
          <span>{data.name}</span>
          <EditFilled
            onClick={() =>
              data?.poaSigned
                ? message.error(
                    'You cannot edit company data as POA is signed!'
                  )
                : setEditModalVisible(true)
            }
          />
          <Popconfirm
            title="Are you sure to delete this company?"
            onConfirm={handleDelete}
          >
            <DeleteFilled />
          </Popconfirm>
        </div>
        <div className="company-details">
          <div className="company-details-header">
            <div className="company-details-header-actions">
              <Popconfirm
                title={`Assign a client ${getUserFullname(owner)} (${
                  data.name
                }) to ${
                  finalAdminList.find(({ auth0 }) => auth0 === popOverVisible)
                    ?.email
                }?`}
                open={!!popOverVisible}
                onCancel={() => setPopOverVisible(false)}
                onConfirm={() =>
                  typeof popOverVisible === 'string' &&
                  handleAssignClient(popOverVisible)
                }
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                  placeholder="Assign to an account manager"
                  value={data?.accountManager?.auth0}
                  onSelect={setPopOverVisible}
                  options={finalAdminList.map(({ auth0, email }) => ({
                    value: auth0,
                    label:
                      email === user.email ? `Assign to me (${email})` : email
                  }))}
                  className="company-details-admin-select"
                >
                  {finalAdminList.map(({ auth0, email }) => (
                    <Option key={auth0} value={auth0}>
                      {email}
                    </Option>
                  ))}
                </Select>
              </Popconfirm>
            </div>
          </div>
          <div className="company-details-info">
            <div className="info-element">
              <span className="label">Created At</span>{' '}
              {new Date(data.createdAt).toLocaleDateString()}
            </div>
            <div className="info-element">
              <span className="label">Company Won At</span>{' '}
              <Datepicker
                value={!!data.companyWonAt ? data.companyWonAt : ''}
                onChange={handleChangeCompanyWonAt}
              />
            </div>
            <div className="info-element">
              <span className="label">Company Address</span>{' '}
              {formatAssessmentAddress(data.address)}
            </div>

            <div className="info-element">
              <span className="label">Customer Status</span>{' '}
              <Select
                value={data.customerStatus}
                placeholder="Customer Status"
                onChange={handleChangeCustomerStatus}
              >
                <Option value="prospect">Prospect</Option>
                <Option value="won">Won</Option>
                <Option value="lost">Lost</Option>
                <Option value="churned">Churned</Option>
              </Select>
            </div>
            <div className="info-element">
              <span className="label">POA</span>
              {!data.poaSigned && (
                <>
                  <Button
                    type="link"
                    onClick={() => {
                      openSendPOAModal();
                    }}
                  >
                    Send poa mail
                  </Button>
                  or
                </>
              )}
              <Upload
                buttonText="Upload"
                file={data.poa}
                queryKeys={['company', data.companyId]}
                belongsTo={data.companyId}
                type="poa_signed"
              />
            </div>
            <div className="info-element">
              <div className="label">Company ID</div>
              <p>{data.companyId}</p>
              <Button type="link" onClick={handleCopyToClipboard}>
                Copy to clipboard
              </Button>
            </div>
            <div className="info-element">
              <div className="label">Headquarters settings</div>
              <Button type="link" onClick={openHeadquartersModal}>
                Open settings
              </Button>
            </div>
            {bankingReports?.reports.length ? (
              <div className="info-element">
                <div className="label">Banking Connection</div>
                <Button type="link" onClick={openBankingIntegrationModal}>
                  Banking info
                </Button>
              </div>
            ) : null}
          </div>
        </div>
        {!arePaymentsLoading && (
          <div className="company-locations">
            <div className="company-locations-header">
              Payment methods ({companyPayments.length})
              <div className="company-locations-header-actions">
                <Button type="link" onClick={() => openPaymentModal()}>
                  <PlusCircleOutlined />
                  New Payment Method
                </Button>
              </div>
            </div>
            <div className="company-payments">
              {companyPayments.map((payment) => (
                <div key={payment.paymentId} className="company-payment-card">
                  <div>
                    {payment.priority === 1 && <Tag color="green">Default</Tag>}
                  </div>
                  <div style={{ flex: 1 }}>{payment.name}</div>
                  <div>
                    <EditFilled
                      onClick={() => openPaymentModal(payment.paymentId)}
                    />
                    <Popconfirm
                      title="Are you sure to delete this payment?"
                      onConfirm={() => handleDeletePayment(payment.paymentId)}
                    >
                      <DeleteFilled />
                    </Popconfirm>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="company-users">
          <div className="company-users-header">
            Users ({data.users.length})
            <div className="company-users-header-actions">
              <Button type="link" onClick={() => openItemDialog()}>
                <PlusCircleOutlined />
                New User
              </Button>
            </div>
          </div>
          <div className="company-users-list">
            {data.users.map((user) => (
              <div className="company-user" key={user.auth0}>
                <div className="company-user-header">
                  <span
                    className={`company-user-name ${
                      isMD(user) || isOwner(user)
                        ? 'company-user-name-highlight'
                        : ''
                    }`}
                  >
                    {isMD(user)
                      ? 'Managing Director - '
                      : isOwner(user)
                        ? 'Owner - '
                        : ''}
                    {getUserFullname(user)}
                  </span>
                  <div className="company-user-header-actions">
                    <EditFilled onClick={() => openItemDialog(user)} />
                    {hasMoreThanOneUser(data.users) && (
                      <DeleteFilled
                        onClick={() => handleRemoveUser(user.auth0)}
                      />
                    )}
                  </div>
                </div>
                <div className="company-user-detail">
                  <div className="info-element">
                    <span className="label">Telephone</span> {user.phone}
                  </div>
                  <div className="info-element">
                    <span className="label">E-Mail</span> {user.email}
                  </div>
                  {isMD(user) && (
                    <div className="info-element">
                      <span className="label">Birthdate</span>{' '}
                      {user.birthdate ? (
                        new Date(user.birthdate).toLocaleDateString()
                      ) : (
                        <strong
                          style={{ color: 'red', cursor: 'pointer' }}
                          onClick={() => openItemDialog(user)}
                        >
                          Add date of birth
                        </strong>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="company-locations">
          <div className="company-locations-header">
            Locations ({data.locations.length})
            <div className="company-locations-header-actions">
              <Button type="link" onClick={() => openLocationModal()}>
                <PlusCircleOutlined />
                New Location
              </Button>
            </div>
          </div>
          <div className="company-locations-list table-container">
            <Table
              paginationKey="locationPagination"
              setSortsAndFilters={setSortsAndFilters}
              columns={columns(
                LocationActions,
                data.companyId,
                sortsAndFilters
              )}
              //fixme - normally would be a makeRecords function but it does not play
              // well with this component, it's going to need a second look
              records={data.locations as any}
              rowKey="locationId"
              searchKey="locationSearch"
            />
          </div>
        </div>
      </div>
      {editModalVisible && (
        <EditCompanyModal
          id={data.companyId}
          visible={editModalVisible}
          onClose={setEditModalVisible}
        />
      )}
      {isLocationModalOpened && (
        <LocationModal
          data={selectedLocationId}
          visible={isLocationModalOpened}
          onClose={closeLocationModal}
        />
      )}
      {isPaymentModalOpened && (
        <PaymentModal
          id={selectedPaymentId}
          visible={isPaymentModalOpened}
          onClose={closePaymentModal}
        />
      )}
      {isOpen && (
        <CompanyUserModal
          id={selectedUser?.auth0}
          visible={isOpen}
          onClose={closeItemDialog}
          hide={{
            birthdate: !isMD(selectedUser),
            name: data.poaSigned && isMD(selectedUser),
            surname: data.poaSigned && isMD(selectedUser)
          }}
        />
      )}
      {isHeadquartersModalOpen && (
        <HeadquartersModal
          bannerUrl={data.bannerUrl}
          isHeadquarters={data.isHeadquarters}
          visible={isHeadquartersModalOpen}
          logoUrl={data.logoUrl}
          onClose={closeHeadquartersModal}
        />
      )}
      {isBankingIntegrationModalOpen && (
        <BankingIntegrationModal
          report={bankingReports?.reports[0]}
          visible={isBankingIntegrationModalOpen}
          onClose={closeBankingIntegrationModal}
        />
      )}

      <SendPOAModal
        visible={isSendPoaModalOpen}
        onClose={closeSendPOAModal}
        companyId={data.companyId}
        MDs={MDs}
      />
    </div>
  );
};
