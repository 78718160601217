import { Input, List, Modal, Typography } from 'antd';
import { paths } from '@/routes';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useProductTemplateById } from '@/services/productTemplates';
import { Loader } from '@/components';

const Content = () => {
  const { productTemplateId } = useParams<{ productTemplateId: string }>();
  const { isLoading, data: productTemplate } =
    useProductTemplateById(productTemplateId);

  if (isLoading) {
    return <Loader />;
  }

  if (!productTemplate) {
    return <div>Product template not found</div>;
  }

  return (
    <>
      <Typography.Title level={4}>Tax</Typography.Title>
      <Input value={productTemplate.tax} />
      <Typography.Title level={4}>Attributes</Typography.Title>
      <List
        bordered
        dataSource={productTemplate.attributes}
        renderItem={(item) => <List.Item>{item.name}</List.Item>}
      />
    </>
  );
};

export const ProductTemplateItem = () => {
  const history = useHistory();

  return (
    <Modal open={true} onCancel={() => history.push(paths.productTemplates)}>
      <Content />
    </Modal>
  );
};
