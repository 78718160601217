import React, { Fragment, useEffect, useState } from 'react';

import {
  Datepicker,
  Loader,
  UpdatableField,
  Uploader,
  YesNo
} from '@/components';
import {
  CategoryInsuranceProductConfiguration,
  paymentPeriodOptions
} from '@/enums';
import { formatMoney, nonNegativeRule, toInt, useItemDialog } from '@/utils';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  DividerProps,
  Empty,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Switch,
  Typography
} from 'antd';
import {
  isAttributeHighlightingDisabled,
  isParameterValueDisabled
} from './helpers';
import {
  append,
  equals,
  isEmpty,
  lensPath,
  omit,
  pluck,
  reject,
  set,
  split,
  toPairs,
  uniq
} from 'ramda';
import { useParams } from 'react-router-dom';
import { InsuranceProductSelect } from './InsuranceProductSelect';
import { MixedBusinessContextModal } from './MixedBusinessContextModal';
import { ValidateModal, ValidateModalProps } from './ValidateModal';
import {
  RecommendationProduct,
  RecommendationProductPriceFetchResult,
  RiskAddress,
  SearchCriteria
} from '@/types/recommendation';
import { InsuranceApiResult, InsuranceProduct } from '@/types/insurance';
import { searchCriteriaSupportedCategories } from '@/pages/RecommendationPage/SearchCriteria';
import { BusinessVerticalWithDefault } from '@/enums/businessVertical';
import { formatRiskAddress } from '@/utils/address';
import { OfferTagSelect } from '@/pages/RecommendationPage/RecommendationProductForm/OfferTagSelect';
import { NumberOrSelectField } from '@/pages/RecommendationPage/RecommendationProductForm/NumberOrSelectField';
import { getOptionsList } from '@/types/productOptions';
import {
  useChosenInsuranceProducts,
  useMetadaModalVisibility,
  usePriceMetadata,
  useProductsActions
} from '@/pages/RecommendationPage/RecommendationProductForm/hooks';
import {
  useCompany,
  useLocationHome,
  usePayments,
  useUpdateRecommendationAttributes
} from '@/services';
import { CarrierLogo } from '@/components/CarrierLogo';
import { formatFuzzyPrice } from '@/pages';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { StarButton } from '@/components/YesNo/StarButton';
import { isAxiosError } from 'axios';
import { useLocationTypes } from '@/services/locationTypes';
import { useAttributesTranslations } from '@/services/attributes';

interface RecommendationProductFormProps {
  categoryId: string;
  businessVertical: BusinessVerticalWithDefault;
  recommendationProducts: RecommendationProduct[];
  locationId: string;
  companyId: string | null;
  products: Partial<InsuranceProduct>[];
  convertedInsuranceId: string | null;
  searchCriteria: SearchCriteria | null;
  openSearchCriteria: () => void;
  locationType: string | null;
  locationSubtypes: string[];
  riskAddress?: RiskAddress;
  visibleAttributes: string[];
  onSearch: () => void;
  insurance: InsuranceApiResult | undefined;
}

const { Option } = Select;

const SlimDivider = (props: DividerProps) => (
  <Divider {...props} style={{ margin: '12px 0px' }} />
);

const getProductIndexes = () => [0, 1, 2];

/**
 * TODO: refactor - this is unbearable complex
 */
export const RecommendationProductForm = ({
  recommendationProducts,
  locationId,
  companyId,
  products,
  categoryId,
  businessVertical,
  convertedInsuranceId,
  searchCriteria,
  openSearchCriteria,
  riskAddress,
  visibleAttributes,
  onSearch,
  insurance
}: RecommendationProductFormProps) => {
  const { recommendationId } = useParams<{
    recommendationId: string;
  }>();
  const doesRecommendationProductExist = (productIndex: number) =>
    !!recommendationProducts[productIndex]?.recommendationProductId;

  const { validateFields, getFieldValue, setFieldValue } =
    Form.useFormInstance();

  const {
    fetchRecommendationProductPrice,
    updateRecommendationProduct,
    updateAllRecommendationProducts
  } = useProductsActions(recommendationProducts);

  const { data: payments = [], isLoading: isPaymentsLoading } = usePayments(
    // TODO: this should be enforced in the props
    String(companyId)
  );
  const { data: company, isLoading: isLoadingCompany } = useCompany(
    // TODO: this should be enforced in the props
    String(companyId)
  );
  const { data: location, isLoading: isLoadingLocation } =
    useLocationHome(locationId);

  const { data: locationTypesData, isLoading: locationTypesLoading } =
    useLocationTypes();

  const { isLoading: isLoadingAttributes, data: attributes } =
    useAttributesTranslations();

  const makeHandleFetchPrice = (productIndex: number) => async () => {
    const result = await fetchRecommendationProductPrice(productIndex);
    setPriceMetadata(
      productIndex,
      omit(['carrierResponse'], result.context.price)
    );
    message.success('Price recalculated successfully');
  };

  const {
    isOpen,
    openItemDialog,
    closeItemDialog,
    item: selectedItem
  } = useItemDialog<ValidateModalProps['data']>();

  const [priceMetadata, setPriceMetadata] = usePriceMetadata();

  const { metadataModalVisibility, openMetadataModal, closeMetadataModal } =
    useMetadaModalVisibility();

  const isHistory = !!convertedInsuranceId;

  const selectedProductIds = recommendationProducts.map(
    (product) => product.insuranceProductId
  );

  // The products need to be eligible in recommendations only if they
  // are not archived, but shown when we're on a historic recommendation
  const eligibleProducts = isHistory
    ? products
    : products.filter(
        (product) =>
          !product.isArchived ||
          (product.insuranceProductId &&
            selectedProductIds.includes(product.insuranceProductId))
      );

  const chosenInsuranceProducts = useChosenInsuranceProducts(
    eligibleProducts,
    recommendationProducts
  );

  const possibleRisksInsured = uniq(searchCriteria?.businessType || []);

  const makeHandleUpdate =
    <T extends string>(
      names: T[],
      productIndex: number,
      modifiers: Partial<Record<T, (a: any) => string | number | string[]>> = {}
    ) =>
    async (): Promise<void> => {
      const values: Record<`${T}_${number}`, unknown> = await validateFields(
        names.map((n): `${T}_${number}` => `${n}_${productIndex}`)
      );

      const cleanedPairs = toPairs(values).map(([key, v]): [T, unknown] => [
        key.split('_')[0] as T,
        v
      ]);

      const payload = cleanedPairs.reduce(
        (acc, [key, v]) => ({
          ...acc,
          [key]: modifiers[key]?.(v) ?? v
        }),
        {} as Record<string, unknown>
      );

      const handleSuccess = (
        data: RecommendationProductPriceFetchResult
      ): void => {
        setFieldValue(`grossPrice_${productIndex}`, data.grossPrice);
        setFieldValue(`netPrice_${productIndex}`, data.netPrice);
        setPriceMetadata(
          productIndex,
          omit(['carrierResponse'], data.context.price)
        );
      };

      if (!!payload['startDate']) {
        const res = await updateAllRecommendationProducts(payload);
        const currenProductRes = res[productIndex];
        if (currenProductRes) {
          handleSuccess(currenProductRes);
        }
      } else {
        try {
          const res = await updateRecommendationProduct(productIndex, payload);
          handleSuccess(res);
        } catch (error) {
          if (isAxiosError(error) && error.response?.data?.message) {
            message.error(error.response?.data?.message);
            return;
          }

          throw error;
        }
      }
    };
  const makeHandleOnChoose = (productIndex: number) => () => {
    validateFields([
      `insuranceProductId_${productIndex}`,
      `startDate_${productIndex}`,
      `deductible_${productIndex}`,
      `grossPrice_${productIndex}`,
      `netPrice_${productIndex}`,
      `paymentPeriod_${productIndex}`,
      `commission_${productIndex}`,
      `amountInsured_${productIndex}`
    ]).then(() => {
      if (company && location && payments) {
        openItemDialog({
          company,
          location,
          payments,

          entity: {
            type: 'recommendation',
            recommendationId:
              recommendationProducts[productIndex].recommendationId,
            recommendationProductId:
              recommendationProducts[productIndex].recommendationProductId
          },

          name: recommendationProducts[productIndex].name,
          startDate: recommendationProducts[productIndex].startDate,
          agreementNumber: recommendationProducts[productIndex].agreementNumber,
          paymentPeriod: recommendationProducts[productIndex].paymentPeriod,
          grossPrice: recommendationProducts[productIndex].grossPrice,
          carrier: {
            name: recommendationProducts[productIndex].carrierData?.name,
            logoUrl: recommendationProducts[productIndex].carrierData?.logoUrl
          }
        });
      }
    });
  };

  const productTemplate =
    // TODO: attributes
    CategoryInsuranceProductConfiguration[categoryId][businessVertical];

  const updateAttributesFromOptions = (
    productIndex: number,
    opt: {
      attributes: string[];
      value: boolean;
      disables: string[];
    }
  ): void => {
    for (const attribute of opt.attributes) {
      setFieldValue(
        [`yesNoValues_${productIndex}`, attribute],
        opt.value ? 1 : 0
      );
    }
    if (opt.disables.length && opt.value) {
      for (const optionToDisable of opt.disables) {
        setFieldValue(
          [`options_${productIndex}`, optionToDisable, 'value'],
          false
        );
      }
    }
  };

  const hasInsuranceToCompare = !!insurance;
  const labelsSpan = 3;
  const columnSpan = hasInsuranceToCompare ? 6 : 7;
  const rowGutter = 16;

  // FROM LABELS
  const updateRecommendationMutation =
    useUpdateRecommendationAttributes(recommendationId);

  const [visibleAttributesState, setVisibleAttributesState] = useState(
    visibleAttributes || []
  );

  useEffect(() => {
    if (
      !convertedInsuranceId &&
      !equals(visibleAttributesState, visibleAttributes)
    ) {
      updateRecommendationMutation.mutate(visibleAttributesState);
    }
    // updateRecommendationMutation can't be in the deps - it triggers infinite render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleAttributesState, visibleAttributes, convertedInsuranceId]);

  const allAttributes = productTemplate
    ? pluck('name', productTemplate.parametersValues)
    : [];

  const showSearchButton =
    searchCriteriaSupportedCategories.includes(categoryId) && !isHistory;
  // END FROM LABELS

  if (
    isLoadingCompany ||
    isLoadingLocation ||
    isPaymentsLoading ||
    locationTypesLoading ||
    isLoadingAttributes
  ) {
    return <Loader />;
  }

  return (
    <>
      <div>
        <Row gutter={rowGutter}>
          <Col span={labelsSpan}>
            <Form.Item>
              {showSearchButton ? (
                <Button type="primary" onClick={onSearch} block>
                  Search
                </Button>
              ) : (
                <div />
              )}
            </Form.Item>
          </Col>
          {getProductIndexes().map((productIndex) => (
            <Col span={columnSpan} key={`buy-${productIndex}`}>
              <Button
                disabled={isHistory}
                onClick={makeHandleOnChoose(productIndex)}
                block
              >
                Buy
              </Button>
            </Col>
          ))}
        </Row>

        <SlimDivider />

        <Row gutter={rowGutter}>
          <Col span={labelsSpan}>
            <div>Product</div>
          </Col>
          {getProductIndexes().map((productIndex) => (
            <Col span={columnSpan} key={`insurance-product-${productIndex}`}>
              <Form.Item
                name={`insuranceProductId_${productIndex}`}
                rules={[{ required: true, message: 'Required!' }]}
              >
                <InsuranceProductSelect
                  recommendationProductId={
                    recommendationProducts[productIndex]
                      ?.recommendationProductId
                  }
                  products={eligibleProducts}
                  index={productIndex}
                  onProductUpdate={(recommendationProduct) => {
                    setPriceMetadata(
                      productIndex,
                      omit(
                        ['carrierResponse'],
                        recommendationProduct.context.price
                      )
                    );
                  }}
                  categoryId={categoryId}
                  businessVertical={businessVertical}
                  disabled={isHistory}
                  value={
                    recommendationProducts[productIndex]?.insuranceProductId
                  }
                />
              </Form.Item>
            </Col>
          ))}
          {hasInsuranceToCompare && (
            <Col span={labelsSpan}>
              <Space>
                <CarrierLogo name={insurance.carrier?.logoUrl || ''} />
                <span>{insurance.name}</span>
              </Space>
            </Col>
          )}
        </Row>

        <SlimDivider />

        <Row gutter={rowGutter}>
          <Col span={labelsSpan}>
            <div />
          </Col>
          {getProductIndexes().map((productIndex) => (
            <Col span={columnSpan} key={`offer-tag-${productIndex}`}>
              <Form.Item name={`offerTag_${productIndex}`}>
                <OfferTagSelect
                  onChange={makeHandleUpdate(['offerTag'], productIndex)}
                  disabled={
                    !doesRecommendationProductExist(productIndex) || isHistory
                  }
                />
              </Form.Item>
            </Col>
          ))}
        </Row>

        <SlimDivider />

        <Row gutter={rowGutter}>
          <Col span={labelsSpan}>
            <div>Discount</div>
          </Col>
          {getProductIndexes().map((productIndex) => (
            <Col span={columnSpan} key={`discount-value-${productIndex}`}>
              <Form.Item name={`discountValue_${productIndex}`}>
                <Select
                  disabled={
                    !doesRecommendationProductExist(productIndex) ||
                    isHistory ||
                    isEmpty(
                      chosenInsuranceProducts[productIndex]
                        ?.formattedDiscountOptions
                    )
                  }
                  onSelect={makeHandleUpdate(['discountValue'], productIndex)}
                >
                  {chosenInsuranceProducts[
                    productIndex
                  ]?.formattedDiscountOptions?.map(({ value, label }) => (
                    <Option key={`discount-${value}`} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          ))}
        </Row>

        <SlimDivider />

        <Row gutter={rowGutter}>
          <Col span={labelsSpan}>
            <div>Start Date</div>
          </Col>
          {getProductIndexes().map((productIndex) => (
            <Col span={columnSpan} key={`start-date-${productIndex}`}>
              <div
                className="search-trigger"
                onClick={() =>
                  // TODO: below condition might not be necessary - investigate
                  searchCriteriaSupportedCategories.includes(categoryId) &&
                  searchCriteria?.startDate &&
                  openSearchCriteria()
                }
              >
                <Form.Item
                  name={`startDate_${productIndex}`}
                  rules={[{ required: true, message: 'Required!' }]}
                >
                  <Datepicker
                    disabled={
                      !doesRecommendationProductExist(productIndex) ||
                      isHistory ||
                      (searchCriteriaSupportedCategories.includes(categoryId) &&
                        !!searchCriteria?.startDate)
                    }
                    onChange={makeHandleUpdate(['startDate'], productIndex)}
                  />
                </Form.Item>
              </div>
            </Col>
          ))}

          {hasInsuranceToCompare && (
            <Col span={labelsSpan}>
              {insurance?.startDate &&
                new Date(insurance.startDate).toLocaleDateString()}
            </Col>
          )}
        </Row>

        <SlimDivider />

        <Row gutter={rowGutter}>
          <Col span={labelsSpan}>
            <div>RaVe-Number</div>
          </Col>
          {getProductIndexes().map((productIndex) => (
            <Col span={columnSpan} key={`agreement-number-${productIndex}`}>
              <Form.Item name={`agreementNumber_${productIndex}`}>
                <Input readOnly disabled={true} />
              </Form.Item>
            </Col>
          ))}
        </Row>
        <SlimDivider />

        <Row gutter={rowGutter}>
          <Col span={labelsSpan}>
            <div>Insurance Sum</div>
          </Col>
          {getProductIndexes().map((productIndex) => (
            <Col span={columnSpan} key={`amount-insured-${productIndex}`}>
              <UpdatableField>
                {() => {
                  const isUnlimited = getFieldValue(
                    `amountInsuredUnlimited_${productIndex}`
                  );

                  const canBeUnlimited = (categoryId: string) => {
                    return (
                      categoryId !== 'LI' &&
                      categoryId !== 'CO' &&
                      categoryId !== 'CY' &&
                      categoryId !== 'FL'
                    );
                  };

                  return (
                    <Row align="middle" gutter={rowGutter}>
                      {canBeUnlimited(categoryId) && (
                        <Col>
                          <Form.Item
                            name={`amountInsuredUnlimited_${productIndex}`}
                            valuePropName="checked"
                            style={{ display: 'inline-block', width: 'unset' }}
                          >
                            <Checkbox
                              onChange={async (e) => {
                                await updateRecommendationProduct(
                                  productIndex,
                                  {
                                    amountInsuredUnlimited: e.target.checked
                                  }
                                );
                                setFieldValue(
                                  `amountInsuredUnlimited_${productIndex}`,
                                  e.target.checked
                                );
                              }}
                              checked={getFieldValue(
                                `amountInsuredUnlimited_${productIndex}`
                              )}
                              disabled={
                                !doesRecommendationProductExist(productIndex) ||
                                isHistory
                              }
                            />
                          </Form.Item>
                        </Col>
                      )}
                      <Col flex="auto">
                        {isUnlimited ? (
                          <Form.Item>
                            <Typography.Text strong>Unlimited</Typography.Text>
                          </Form.Item>
                        ) : (
                          <NumberOrSelectField
                            name={`amountInsured_${productIndex}`}
                            rules={[
                              { required: true, message: 'Required!' },
                              nonNegativeRule
                            ]}
                            selectOptions={
                              chosenInsuranceProducts[productIndex]
                                ?.formattedInsuranceSumsOptions || null
                            }
                            handleChange={makeHandleUpdate(
                              ['amountInsured'],
                              productIndex
                            )}
                            disabled={
                              !doesRecommendationProductExist(productIndex) ||
                              isHistory
                            }
                          />
                        )}
                      </Col>
                    </Row>
                  );
                }}
              </UpdatableField>
            </Col>
          ))}
          {hasInsuranceToCompare && (
            <Col span={labelsSpan}>
              {insurance.amountInsuredUnlimited
                ? 'Unlimited'
                : formatMoney(insurance.amountInsured || 0)}
            </Col>
          )}
        </Row>

        {categoryId === 'CO' && (
          <>
            <SlimDivider />
            <Row gutter={rowGutter}>
              <Col span={labelsSpan}>
                <div>Loss of Earnings</div>
              </Col>
              {getProductIndexes().map((productIndex) => (
                <Col span={columnSpan} key={`loss-of-earnings-${productIndex}`}>
                  <Form.Item
                    name={`lossOfEarnings_${productIndex}`}
                    rules={[{ required: true, message: 'Required!' }]}
                  >
                    <Input
                      addonBefore="€"
                      disabled={
                        !doesRecommendationProductExist(productIndex) ||
                        isHistory ||
                        !!chosenInsuranceProducts[productIndex]?.disabledFields
                          ?.lossOfEarnings
                      }
                      onBlur={makeHandleUpdate(
                        ['lossOfEarnings'],
                        productIndex,
                        {
                          lossOfEarnings: toInt
                        }
                      )}
                    />
                  </Form.Item>
                </Col>
              ))}
              {hasInsuranceToCompare && (
                <Col span={labelsSpan}>
                  {formatFuzzyPrice(
                    insurance.parametersValues?.en?.lossOfEarnings ||
                      insurance.parametersValues?.de?.lossOfEarnings ||
                      '-'
                  )}
                </Col>
              )}
            </Row>
          </>
        )}

        <SlimDivider />

        <Row gutter={rowGutter}>
          <Col span={labelsSpan}>
            <div>Deductible</div>
          </Col>
          {getProductIndexes().map((productIndex) => (
            <Col span={columnSpan} key={`deductible-${productIndex}`}>
              <NumberOrSelectField
                name={`deductible_${productIndex}`}
                rules={[
                  { required: true, message: 'Required!' },
                  nonNegativeRule
                ]}
                selectOptions={
                  chosenInsuranceProducts[productIndex]
                    ?.formattedDeductiblesOptions || null
                }
                handleChange={makeHandleUpdate(['deductible'], productIndex)}
                disabled={
                  !doesRecommendationProductExist(productIndex) || isHistory
                }
              />
            </Col>
          ))}

          {hasInsuranceToCompare && (
            <Col span={labelsSpan}>
              {formatMoney(insurance.deductible || 0)}
            </Col>
          )}
        </Row>

        {categoryId === 'CO' && riskAddress && (
          <>
            <SlimDivider />
            <Row gutter={rowGutter}>
              <Col span={labelsSpan}>
                <div>Risk address</div>
              </Col>
              {getProductIndexes().map((productIndex) => (
                <Col span={columnSpan} key={`risk-address-${productIndex}`}>
                  <Form.Item>{formatRiskAddress(riskAddress)}</Form.Item>
                </Col>
              ))}
              {hasInsuranceToCompare && (
                <Col span={labelsSpan}>{formatRiskAddress(riskAddress)}</Col>
              )}
            </Row>
          </>
        )}

        <SlimDivider />
        <Row gutter={rowGutter}>
          <Col span={labelsSpan}>
            <div>Risks Insured</div>
          </Col>
          {getProductIndexes().map((productIndex) => (
            <Col span={columnSpan} key={`risk-insured-${productIndex}`}>
              <Form.Item name={`risksInsured_${productIndex}`}>
                <Select
                  onChange={makeHandleUpdate(['risksInsured'], productIndex)}
                  disabled={
                    !doesRecommendationProductExist(productIndex) || isHistory
                  }
                >
                  {possibleRisksInsured.map((item) => (
                    <Option key={`insured-risk-${item}`} value={item}>
                      {locationTypesData?.getLocationLabel(item) ||
                        recommendationProducts[productIndex]?.risksInsured}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          ))}

          {hasInsuranceToCompare && (
            <Col span={labelsSpan}>
              {insurance.risksInsured
                ? locationTypesData?.getLocationLabel(insurance.risksInsured) ||
                  insurance.risksInsured
                : insurance.risksInsured}
            </Col>
          )}
        </Row>

        <SlimDivider />
        <Row gutter={rowGutter}>
          <Col span={labelsSpan}>
            <div>Price</div>
          </Col>
          {getProductIndexes().map((productIndex) => (
            <Col span={columnSpan} key={`price-${productIndex}`}>
              <div className="add-insurance-column">
                <div className="add-insurance-row">
                  <UpdatableField>
                    <Form.Item
                      className="add-insurance-form-item"
                      name={`grossPrice_${productIndex}`}
                      rules={[
                        { required: true, message: 'Required!' },
                        nonNegativeRule
                      ]}
                    >
                      <Input
                        onBlur={makeHandleUpdate(['grossPrice'], productIndex)}
                        disabled={
                          !doesRecommendationProductExist(productIndex) ||
                          isHistory
                        }
                        type="number"
                        addonBefore="€"
                        placeholder="gross"
                      />
                    </Form.Item>
                  </UpdatableField>
                  <Form.Item
                    className="add-insurance-form-item"
                    name={`netPrice_${productIndex}`}
                    rules={[nonNegativeRule]}
                  >
                    <Input
                      onBlur={makeHandleUpdate(['netPrice'], productIndex)}
                      disabled={
                        !doesRecommendationProductExist(productIndex) ||
                        isHistory
                      }
                      type="number"
                      addonBefore="€"
                      placeholder="net"
                    />
                  </Form.Item>
                </div>
                <div className="metadata-wrapper">
                  {priceMetadata[productIndex] && (
                    <Typography.Link
                      onClick={() => openMetadataModal(productIndex)}
                    >
                      {`Show metadata (${priceMetadata[productIndex].status})`}
                    </Typography.Link>
                  )}
                  <Button
                    disabled={isHistory}
                    onClick={makeHandleFetchPrice(productIndex)}
                  >
                    Fetch price
                  </Button>
                </div>
                {priceMetadata[productIndex] && (
                  <MixedBusinessContextModal
                    visible={metadataModalVisibility[productIndex]}
                    handleMetadataModalClose={() =>
                      closeMetadataModal(productIndex)
                    }
                    priceMetadata={priceMetadata[productIndex]}
                  />
                )}
              </div>
            </Col>
          ))}

          {hasInsuranceToCompare && (
            <Col span={labelsSpan}>
              Gross {formatMoney(insurance.grossPrice || 0)} / Net{' '}
              {formatMoney(insurance.netPrice || 0)}
            </Col>
          )}
        </Row>

        <Row gutter={rowGutter} style={{ marginTop: 8 }}>
          <Col span={labelsSpan}>
            <div />
          </Col>
          {getProductIndexes().map((productIndex) => (
            <Col
              span={columnSpan}
              key={`is-search-engine-price-${productIndex}`}
            >
              {recommendationProducts[productIndex]?.isSearchEnginePrice && (
                <Alert
                  type="success"
                  message="Search Engine price"
                  description="Parameters can be edited"
                  showIcon
                />
              )}
              {recommendationProducts[productIndex]?.isSearchEnginePrice ===
                false && (
                <Alert
                  type="warning"
                  message="Manual price"
                  description="Parameters can only be edited with approval"
                  showIcon
                />
              )}
            </Col>
          ))}
        </Row>

        <SlimDivider />

        <Row gutter={rowGutter}>
          <Col span={labelsSpan}>
            <div>Payment Period</div>
          </Col>
          {getProductIndexes().map((productIndex) => (
            <Col span={columnSpan} key={`payment-period-${productIndex}`}>
              <div
                className="search-trigger"
                onClick={() =>
                  // TODO: below condition might not be necessary - investigate
                  searchCriteriaSupportedCategories.includes(categoryId) &&
                  searchCriteria?.startDate &&
                  openSearchCriteria()
                }
              >
                <Form.Item
                  name={`paymentPeriod_${productIndex}`}
                  rules={[{ required: true, message: 'Required!' }]}
                >
                  <Select
                    onChange={makeHandleUpdate(['paymentPeriod'], productIndex)}
                    disabled={
                      !recommendationProducts[productIndex]?.grossPrice ||
                      isHistory ||
                      (searchCriteriaSupportedCategories.includes(categoryId) &&
                        !!searchCriteria?.paymentPeriod)
                    }
                  >
                    {paymentPeriodOptions.map(({ label, value }) => (
                      <Option
                        key={value}
                        value={value}
                        disabled={
                          recommendationProducts[productIndex]?.grossPrice &&
                          value === 'non-contributory'
                        }
                      >
                        {label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
          ))}
        </Row>

        <SlimDivider />
        <Row gutter={rowGutter}>
          <Col span={labelsSpan}>
            <div>Commission</div>
          </Col>
          {getProductIndexes().map((productIndex) => (
            <Col span={columnSpan} key={`commision-${productIndex}`}>
              <Form.Item
                name={`commission_${productIndex}`}
                rules={[
                  { required: true, message: 'Required!' },
                  nonNegativeRule
                ]}
              >
                <Input
                  onBlur={makeHandleUpdate(['commission'], productIndex)}
                  disabled={
                    !doesRecommendationProductExist(productIndex) || isHistory
                  }
                  type="number"
                  addonBefore="%"
                />
              </Form.Item>
            </Col>
          ))}
          {hasInsuranceToCompare && (
            <Col span={labelsSpan}>{insurance.commission || 0}%</Col>
          )}
        </Row>

        <SlimDivider />
        <Row gutter={rowGutter}>
          <Col span={labelsSpan}>
            <div>Highlighted text</div>
          </Col>
          {getProductIndexes().map((productIndex) => (
            <Col span={columnSpan} key={`${productIndex}`}>
              <Form.Item name={`highlightText_${productIndex}`}>
                {/* FIXME: below is broken: when loading data it does not setup commas*/}
                <Input.TextArea
                  onBlur={makeHandleUpdate(['highlightText'], productIndex, {
                    highlightText: split(', ')
                  })}
                  disabled={
                    !doesRecommendationProductExist(productIndex) || isHistory
                  }
                  placeholder="Add a comma&space separated list of words"
                  autoSize={{ minRows: 3 }}
                />
              </Form.Item>
            </Col>
          ))}
        </Row>

        <SlimDivider />
        <Row gutter={rowGutter}>
          <Col span={labelsSpan}>
            <div>Highlighted fields</div>
          </Col>
          {getProductIndexes().map((productIndex) => (
            <Col span={columnSpan} key={`highlighted-fields-${productIndex}`}>
              <Form.Item name={`highlightFields_${productIndex}`}>
                <Select
                  onChange={makeHandleUpdate(['highlightFields'], productIndex)}
                  disabled={
                    !doesRecommendationProductExist(productIndex) || isHistory
                  }
                  mode="multiple"
                >
                  <Option key="grossPrice" value="grossPrice">
                    Price
                  </Option>
                  <Option key="deductible" value="deductible">
                    Deductible
                  </Option>
                  <Option key="commission" value="commission">
                    Commission Rate
                  </Option>
                  <Option key="amountInsured" value="amountInsured">
                    Insurance Sum
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          ))}
        </Row>

        <SlimDivider />
        <Row gutter={rowGutter}>
          <Col span={labelsSpan}>
            <div>Options</div>
          </Col>
          {getProductIndexes().map((productIndex) => (
            <Col span={columnSpan} key={`options-${productIndex}`}>
              {recommendationProducts[productIndex]?.options ? (
                <Space direction="vertical" align="start">
                  {getOptionsList(
                    recommendationProducts[productIndex].options
                  ).map(({ name, label, attributes, disables = [] }) => (
                    <Space
                      key={`${productIndex}-${name}`}
                      direction="horizontal"
                      align="baseline"
                    >
                      <Form.Item
                        name={[`options_${productIndex}`, name, 'value']}
                        valuePropName="checked"
                        style={{ margin: '0' }}
                      >
                        <Switch
                          disabled={isHistory}
                          onChange={async (value) => {
                            const currentState = getFieldValue(
                              `options_${productIndex}`
                            );
                            const newState = set(
                              lensPath([name, 'value']),
                              value,
                              currentState
                            );
                            setFieldValue(`options_${productIndex}`, newState);
                            updateAttributesFromOptions(productIndex, {
                              attributes,
                              value,
                              disables
                            });
                            await makeHandleUpdate(
                              ['options', 'yesNoValues'],
                              productIndex
                            )();
                          }}
                        />
                      </Form.Item>
                      {/* we use plain label here because in vertical form it's not possible to have some fields with horizontal layout*/}
                      <label
                        htmlFor={['options', productIndex, name, 'value'].join(
                          '_'
                        )}
                        style={{ cursor: 'pointer' }}
                      >
                        {label}
                      </label>
                    </Space>
                  ))}
                </Space>
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={'No options'}
                />
              )}
            </Col>
          ))}
        </Row>

        {productTemplate?.parametersValues && (
          <>
            <SlimDivider />
            <Row gutter={rowGutter}>
              <Col span={labelsSpan}>
                <div>
                  <Space>
                    <span>Attributes</span>
                    <Switch
                      checkedChildren="Hide all"
                      unCheckedChildren="Show all"
                      checked={
                        allAttributes.length === visibleAttributesState.length
                      }
                      disabled={isHistory}
                      onChange={(value) => {
                        if (value) {
                          setVisibleAttributesState(allAttributes);
                        } else {
                          setVisibleAttributesState([]);
                        }
                      }}
                    />
                  </Space>
                </div>
              </Col>
            </Row>

            {productTemplate.parametersValues.map(({ name }) => (
              <Fragment key={name}>
                <SlimDivider />
                <Row gutter={rowGutter} key={name}>
                  <Col span={labelsSpan}>
                    <div>
                      <i className="parameter-value-label">
                        {attributes?.getAttributeLabel(name)}
                      </i>
                    </div>
                    <Switch
                      checkedChildren="Hide"
                      unCheckedChildren="Show"
                      checked={visibleAttributesState.includes(name)}
                      disabled={isHistory}
                      onChange={(e) => {
                        if (e) {
                          setVisibleAttributesState(append(name));
                        } else {
                          setVisibleAttributesState(reject(equals(name)));
                        }
                      }}
                    />
                  </Col>

                  {getProductIndexes().map((productIndex) => {
                    const shouldDisableTextInput =
                      !doesRecommendationProductExist(productIndex) ||
                      isHistory ||
                      isParameterValueDisabled(
                        chosenInsuranceProducts[productIndex],
                        name
                      );

                    return (
                      <Col span={columnSpan} key={productIndex}>
                        <div>
                          <div style={{ display: 'flex', gap: 10 }}>
                            <Form.Item
                              name={[`yesNoValues_${productIndex}`, name]}
                            >
                              <YesNo
                                disabled={
                                  !doesRecommendationProductExist(
                                    productIndex
                                  ) || isHistory
                                }
                                onChangeAddon={makeHandleUpdate(
                                  ['yesNoValues'],
                                  productIndex
                                )}
                              />
                            </Form.Item>
                            <Form.Item
                              name={[
                                `highlightedAttributes_${productIndex}`,
                                name
                              ]}
                              initialValue={
                                recommendationProducts[productIndex]
                                  ?.highlightedAttributes?.[name]
                              }
                            >
                              <StarButton
                                onChangeAddon={makeHandleUpdate(
                                  ['highlightedAttributes'],
                                  productIndex
                                )}
                                disabled={isAttributeHighlightingDisabled(
                                  recommendationProducts[productIndex]
                                    ?.highlightedAttributes as Record<
                                    string,
                                    boolean
                                  >,
                                  name
                                )}
                              />
                            </Form.Item>
                          </div>
                          <Form.Item
                            name={[
                              `parametersValues_${productIndex}`,
                              'de',
                              name
                            ]}
                          >
                            <Input
                              disabled={shouldDisableTextInput}
                              addonBefore={'German'}
                              onBlur={makeHandleUpdate(
                                ['parametersValues'],
                                productIndex
                              )}
                            />
                          </Form.Item>
                          <Form.Item
                            name={[
                              `parametersValues_${productIndex}`,
                              'en',
                              name
                            ]}
                          >
                            <Input
                              disabled={shouldDisableTextInput}
                              addonBefore={'English'}
                              onBlur={makeHandleUpdate(
                                ['parametersValues'],
                                productIndex
                              )}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    );
                  })}

                  {hasInsuranceToCompare && (
                    <Col span={labelsSpan}>
                      <Form.Item>
                        {insurance?.yesNoValues?.[name] ? (
                          <>
                            <CheckCircleFilled style={{ color: '#2cd3a1' }} />{' '}
                            Yes
                          </>
                        ) : insurance?.yesNoValues?.[name] === 0 ? (
                          <>
                            <CloseCircleFilled style={{ color: '#ff5656' }} />{' '}
                            No
                          </>
                        ) : null}
                      </Form.Item>
                      <Form.Item>
                        <Input
                          value={insurance?.parametersValues?.de?.[name]}
                          disabled
                          addonBefore={'German'}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Input
                          value={insurance?.parametersValues?.en?.[name]}
                          disabled
                          addonBefore={'English'}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Fragment>
            ))}
          </>
        )}

        <SlimDivider />

        <Row gutter={rowGutter}>
          <Col span={labelsSpan}>
            <div>Files</div>
          </Col>
          {getProductIndexes().map((productIndex) => (
            <Col span={columnSpan} key={`files-${productIndex}`}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start'
                }}
              >
                <Uploader
                  max={10}
                  files={
                    recommendationProducts[productIndex]
                      ?.recommendationProductFiles
                  }
                  queryKeys={['recommendationProducts', recommendationId]}
                  belongsTo={
                    recommendationProducts[productIndex]
                      ?.recommendationProductId
                  }
                  type="recommendation_product"
                  disabled={isHistory}
                  category="offer"
                />
              </div>
            </Col>
          ))}
        </Row>
      </div>

      {isOpen && selectedItem && (
        <ValidateModal
          visible={isOpen}
          onClose={closeItemDialog}
          data={selectedItem}
        />
      )}
    </>
  );
};
