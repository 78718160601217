import { useQuery, UseQueryOptions } from 'react-query';
import {
  getProductTemplateById,
  listProductTemplates,
  ProductTemplatesApiResponse,
  ProductTemplateItemApiResponse
} from '@/api/productTemplates';

const keys = {
  productTemplates: 'productTemplates'
};

export const useProductTemplates = (
  options: UseQueryOptions<
    ProductTemplatesApiResponse,
    unknown,
    ProductTemplatesApiResponse,
    string
  > = {}
) => useQuery(keys.productTemplates, listProductTemplates, options);

export const useProductTemplateById = (id: string) =>
  useQuery<
    ProductTemplateItemApiResponse,
    unknown,
    ProductTemplateItemApiResponse,
    string[]
  >([keys.productTemplates, id], () => getProductTemplateById(id));
