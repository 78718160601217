import { useAttributesDefinitions } from '@/services/attributes';
import { Loader } from '@/components';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd';

const columns: ColumnsType<{
  code: string;
  nameEN: string;
  nameDE: string;
  descriptionEN?: string;
  descriptionDE?: string;
}> = [
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Name (EN)',
    dataIndex: 'nameEN',
    key: 'nameEN'
  },
  {
    title: 'Name (DE)',
    dataIndex: 'nameDE',
    key: 'nameDE'
  },
  {
    title: 'Description (EN)',
    dataIndex: 'descriptionEN',
    key: 'descriptionEN'
  },
  {
    title: 'Description (DE)',
    dataIndex: 'descriptionDE',
    key: 'descriptionDE'
  }
];

export const Attributes = () => {
  const { isLoading, data: attributesDefinitions } = useAttributesDefinitions();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <h1>Attributes</h1>
      <Table columns={columns} dataSource={attributesDefinitions} />
    </div>
  );
};
