import {
  getAttributesDefinitionsList,
  getAttributesTranslations
} from '@/api/attributes';
import { useQuery } from 'react-query';

const keys = {
  attributesTranslations: 'attributes-translations',
  attributesDefinitions: 'attributes-definitions'
};

export const useAttributesTranslations = () => {
  return useQuery(
    keys.attributesTranslations,
    () => getAttributesTranslations('en'),
    {
      staleTime: Infinity,
      cacheTime: Infinity
    }
  );
};

export const useAttributesDefinitions = () => {
  return useQuery(keys.attributesDefinitions, () =>
    getAttributesDefinitionsList()
  );
};
