import { Button, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { useProductTemplates } from '@/services/productTemplates';
import { Loader } from '@/components';

const columns: ColumnsType<{
  type: 'category' | 'vertical';
  id: string;
  name: string;
}> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (_, { id, name }) => (
      <Link to={`/product-templates/${id}`}>{name}</Link>
    )
  },
  {
    title: 'Action',
    width: '20%',
    key: 'action',
    render: (_, { type }) => (
      <Space size="middle">
        {type === 'category' && <Button>Add vertical</Button>}
      </Space>
    )
  }
];

export const ProductTemplates = () => {
  const { isLoading, data: productTemplates } = useProductTemplates();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography.Title level={2}>Product Templates</Typography.Title>
        <Link to="/attributes">Edit attributes</Link>
      </div>
      <Table
        expandable={{ defaultExpandAllRows: true }}
        columns={columns}
        dataSource={productTemplates}
      />
    </div>
  );
};
