import React, { useState } from 'react';

import { Loader, Table } from '@/components';
import {
  useDeleteCompany,
  useDownloadFile,
  useListCompanies,
  useUpdateCompany
} from '@/services';
import { truncate, useItemDialog, useLocalStorage } from '@/utils';
import { MenuOutlined } from '@ant-design/icons';
import { Button, Checkbox, Dropdown, Menu, Popconfirm } from 'antd';
import { compose, map, pipe, prop, reverse, sortBy } from 'ramda';
import { AddCompanyModal } from './AddCompanyModal';
import { EditCompanyModal } from './EditCompanyModal';
import './styles.scss';
import { formatAssessmentAddress } from '@/utils/address';
import { Link } from 'react-router-dom';

const transform = pipe(
  map(({ owner, ...item }) => ({
    ...item,
    owner: owner
      ? {
          ...owner,
          fullname: `${owner.name} ${owner.surname}`
        }
      : undefined,
    companyAddress: formatAssessmentAddress(item.address)
  })),
  sortBy(compose(Date.parse, prop('createdAt'))),
  reverse
);

const truncateFilename = truncate(12);

export const Dashboard = () => {
  const {
    openItemDialog,
    closeItemDialog,
    isOpen,
    item: selectedId
  } = useItemDialog();
  const { data, isLoading } = useListCompanies({
    select: transform
  });

  const [visible, setVisible] = useState(false);

  const [sortsAndFilters, setSortsAndFilters] = useLocalStorage(
    'companySortAndFilters',
    {
      sorts: {},
      filters: {}
    }
  );
  const updateCompanyMutation = useUpdateCompany();
  const deleteCompanyMutation = useDeleteCompany();

  const previewFile = useDownloadFile();

  const DashboardActions = ({ id, editDisabled }) => (
    <Menu
      items={[
        {
          label: 'Edit',
          disabled: editDisabled,
          onClick: () => openItemDialog(id)
        },
        {
          label: (
            <Popconfirm
              title="Are you sure to delete this company?"
              onConfirm={() => deleteCompanyMutation.mutate(id)}
            >
              <div style={{ color: 'red' }}>Delete</div>
            </Popconfirm>
          )
        }
      ]}
    />
  );

  const makeRecords = (search) =>
    data?.filter(
      ({ name, companyId }) =>
        name.toLowerCase().includes(search.toLowerCase()) ||
        companyId.toLowerCase() === search.toLowerCase()
    );

  const columns = [
    {
      title: 'Company Name',
      dataIndex: 'name',
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
      sortOrder:
        sortsAndFilters.sorts.field === 'name'
          ? sortsAndFilters.sorts.order
          : null,
      render: (name, { companyId }) => (
        <Link to={`/companies/${companyId}/`}>{name}</Link>
      )
    },
    { title: 'Address', dataIndex: ['companyAddress'] },
    {
      title: 'Owner',
      dataIndex: ['owner', 'fullname'],
      sortOrder:
        JSON.stringify(sortsAndFilters.sorts.field) ===
        JSON.stringify(['owner', 'fullname'])
          ? sortsAndFilters.sorts.order
          : null,
      sorter: (a, b) => a?.owner?.fullname?.localeCompare(b?.owner?.fullname)
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      sortOrder:
        sortsAndFilters.sorts.field === 'createdAt'
          ? sortsAndFilters.sorts.order
          : null,
      render: (date) => new Date(date).toLocaleDateString(),
      sorter: (a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt)
    },
    {
      title: 'Account Manager',
      dataIndex: ['accountManager', 'name'],
      sortOrder:
        JSON.stringify(sortsAndFilters.sorts.field) ===
        JSON.stringify(['accountManager', 'name'])
          ? sortsAndFilters.sorts.order
          : null,
      sorter: (a, b) =>
        a?.accountManager?.name?.localeCompare(b?.accountManager?.name)
    },
    {
      title: 'POA',
      dataIndex: 'poa',
      filters: [
        {
          text: 'Poa signed',
          value: true
        },
        {
          text: 'Poa not signed',
          value: false
        }
      ],
      filteredValue: sortsAndFilters.filters.poa || null,
      onFilter: (value, { poaSigned }) => poaSigned === value,
      render: ({ fileName, name } = {}) =>
        fileName ? (
          <Button
            type="link"
            onClick={() => {
              previewFile.mutate(name);
            }}
          >
            {truncateFilename(fileName)}
          </Button>
        ) : (
          'Not signed'
        )
    },
    {
      title: 'Is Test',
      dataIndex: 'isTest',
      render: (isTest, { companyId }) => (
        <Checkbox
          checked={isTest}
          onChange={(e) =>
            updateCompanyMutation.mutate({
              companyId,
              isTest: e.target.checked
            })
          }
        />
      ),
      filters: [
        {
          text: 'Is test company',
          value: true
        },
        {
          text: 'Is real company',
          value: false
        }
      ],
      filteredValue: sortsAndFilters.filters.isTest || null,
      onFilter: (value, { isTest }) => isTest === value
    },
    {
      title: '',
      render: (__, { companyId, poaSigned }) => (
        <Dropdown
          overlay={<DashboardActions id={companyId} editDisabled={poaSigned} />}
        >
          <MenuOutlined style={{ cursor: 'pointer' }} />
        </Dropdown>
      )
    }
  ];
  if (isLoading) return <Loader />;

  return (
    <div>
      {visible && <AddCompanyModal visible={visible} setVisible={setVisible} />}
      <div className="table-container">
        <Table
          searchKey="dashboardSearch"
          buttonText="Neue Firma"
          searchPlaceholder="Search by company name or id"
          onButtonClick={() => setVisible(true)}
          setSortsAndFilters={setSortsAndFilters}
          rowKey="companyId"
          columns={columns}
          records={makeRecords}
          paginationKey="companypagination"
        />
      </div>
      {isOpen && (
        <EditCompanyModal
          id={selectedId}
          visible={isOpen}
          onClose={closeItemDialog}
        />
      )}
    </div>
  );
};
