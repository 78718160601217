/* eslint-disable react/react-in-jsx-scope */
import { autoRecommendationKeys, CategoryLabelMap } from '@/enums';
import {
  useCompanyLocations,
  useDeleteLocation,
  useDeleteRecommendations,
  useRemoveUserFromCompany,
  useSelfAssesment,
  useSendRecommendations,
  useWithdrawRecommendations
} from '@/services';
import {
  capitalize,
  getUserFullname,
  returns,
  timestampToDateString,
  useGoBack,
  useGoToPath,
  useItemDialog
} from '@/utils';
import {
  BuildOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DeleteFilled,
  EditFilled,
  LeftCircleOutlined,
  PlusCircleOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Button, Divider, Popconfirm, Select, Tooltip } from 'antd';
import {
  __,
  append,
  both,
  complement,
  concat,
  equals,
  filter,
  ifElse,
  includes,
  isEmpty,
  pipe,
  pluck,
  prop,
  propEq,
  reject,
  uniq,
  uniqBy,
  when
} from 'ramda';
import React, { useState } from 'react';
import { LocationModal } from '../CompanyOverview/LocationModal';
import { AddInsuranceModal } from './AddInsuranceModal';
import { AddRecommendation } from './AddRecommendationModal';
import { AssesmentModal } from './AssesmentModal';
import { EditInsuranceModal } from './EditInsuranceModal';
import { HistoryModal } from './HistoryModal';
import { BoughtInsuranceCard, InsuranceCard } from './InsuranceCard';
import { KillerQuestionModal } from './KillerQuestionModal';
import { SelfAssesmentModal } from './SelfAssesmentModal';

import { Company } from '@/types/company';
import { LocationHomeInsuranceEntry } from '@/types/insurance';
import { Location, LocationHome } from '@/types/location';
import { Recommendation, RecommendationProduct } from '@/types/recommendation';
import { Assessment } from '@/types/self-assessment';
import { User } from '@/types/user';
import './styles.scss';
import { formatAssessmentAddress } from '@/utils/address';
import { CompanyUserModal } from '../CompanyOverview/CompanyUserModal';
import { useLocationTypes } from '@/services/locationTypes';

type Essentials =
  | {
      type: 'missingEssentialsWithRecommendation' | 'missingEssentials';
      categoryName: string;
      categoryId: string;
    }
  | ({
      type: 'essentialInsurances';
      categoryName: string;
      categoryId: string;
    } & LocationHomeInsuranceEntry);

type Others =
  | {
      type: 'missingOthers';
      categoryName: string;
      categoryId: string;
    }
  | ({
      type: 'otherInsurances';
      categoryName: string;
      categoryId: string;
    } & LocationHomeInsuranceEntry);

interface LocationOverviewData extends LocationHome {
  companyName: Company['name'];
  userData: User | undefined;
  users: User[];
}

const { Option } = Select;

const makeLocationsOptions = (data: Location[]) =>
  data.map(({ locationId, name }) => ({ value: locationId, label: name }));

const useLocationTypeLabel = ({
  locationType,
  locationSubtypes,
  locationTypeSpecification
}: LocationOverviewData) => {
  const { data: locationTypesData, isLoading: locationTypesLoading } =
    useLocationTypes();

  if (locationTypesLoading) return 'Loading...';

  if (locationType === 'other') {
    return `Other: ${locationTypeSpecification}`;
  }
  return [locationType, ...locationSubtypes]
    .map((type) => locationTypesData?.getLocationLabel(type) || type)
    .join(' / ');
};

const getAssessment = (assessments: Assessment[], categoryId: string) =>
  assessments.find(
    (assessment: Assessment) => assessment.categoryId === categoryId
  );

const getEssentialInsurancesRatio = (
  existing: LocationHomeInsuranceEntry[],
  data: LocationHome
) => {
  const has = uniqBy(prop('categoryId'), existing).length;
  const shouldHaveTotal = data.essentialCategoryId.length;

  return {
    ratio: has / shouldHaveTotal,
    text: `${has} / ${shouldHaveTotal}`
  };
};

const getMissingEssentials = (
  existing: LocationHomeInsuranceEntry[],
  essentials: string[]
) => {
  return essentials.filter(
    (item) => !pluck('categoryId', existing).includes(item)
  );
};

// fixme
const getMissingInsurancesWithRecommendation = (
  recommendations: RecommendationProduct[],
  essentials: string[],
  forEssentials: boolean
) =>
  pipe(
    reject(prop<any>('insuranceId')),
    pluck('categoryId') as any,
    concat([...autoRecommendationKeys]),
    reject(
      when<any, any>(
        returns(forEssentials) as any,
        complement,
        includes(__, essentials)
      )
    ) as any,
    uniq<string>
  )(recommendations);

// fixme
const findRecommendationBy =
  (data: LocationOverviewData) =>
  ({ insuranceId, categoryId }: any) =>
    pipe(
      prop('recommendations') as any,
      filter(
        ifElse(
          returns(Boolean(insuranceId)) as any,
          propEq('insuranceId', insuranceId),
          both(
            complement(prop('insuranceId')),
            propEq('categoryId', categoryId)
          ) as any
        )
      )
    )(data) as Recommendation[];

const SelectMode = {
  send: 'send',
  withdraw: 'withdraw',
  delete: 'delete'
} as const;

export const LocationOverview = ({ data }: { data: LocationOverviewData }) => {
  const combinedAssessment = getAssessment(data.assessments, 'CL');
  const legalAssessment = getAssessment(data.assessments, 'LE');

  const locationTypeLabel = useLocationTypeLabel(data);

  const { data: locationTypesData, isLoading: locationTypesLoading } =
    useLocationTypes();

  const goToOverview = useGoToPath(pipe(String, concat('/companies/')));
  const goToLocation = useGoToPath(concat(`/companies/${data.companyId}/`));
  const goToRecommendation = useGoToPath(
    pipe(String, concat('/recommendation/'))
  );
  const goBack = useGoBack();

  const { data: companyLocations } = useCompanyLocations(data.companyId, {
    select: makeLocationsOptions
  });

  const { data: selfAssessment } = useSelfAssesment(data.locationId, {});

  const deleteLocationMutation = useDeleteLocation(data.locationId);
  const deleteRecommendationsMutation = useDeleteRecommendations();
  const withdrawRecommendationsMutation = useWithdrawRecommendations();
  const sendRecommendationsMutation = useSendRecommendations();
  const removeUserMutation = useRemoveUserFromCompany(
    data.companyId,
    data.locationId
  );

  const handleRemoveUser = (userId: string) =>
    removeUserMutation.mutate(userId);

  const {
    item: selectedUser,
    openItemDialog,
    closeItemDialog,
    isOpen
  } = useItemDialog<User>();

  const {
    item: selectedLocationId,
    openItemDialog: openLocationModal,
    closeItemDialog: closeLocationModal,
    isOpen: isLocationModalOpened
  } = useItemDialog<string>();

  const {
    item: selectedInsuranceId,
    openItemDialog: openInsuranceModal,
    closeItemDialog: closeInsuranceModal,
    isOpen: isInsuranceModalOpened
  } = useItemDialog<string>();

  const [mode, setMode] = useState<keyof typeof SelectMode | undefined>();
  const [selectedRecommendations, setSelectedRecommendations] = useState<
    string[]
  >([]);

  const handleDelete = () =>
    deleteLocationMutation
      .mutateAsync(undefined)
      .then(() => goToOverview(data.companyId));

  const handleDeleteRecommendations = () =>
    deleteRecommendationsMutation
      .mutateAsync(selectedRecommendations)
      .then(() => setSelectedRecommendations([]))
      .catch(() => setSelectedRecommendations([]));

  const handleWithdrawRecommendations = () => {
    withdrawRecommendationsMutation
      .mutateAsync(selectedRecommendations)
      .then(() => setSelectedRecommendations([]))
      .catch(() => setSelectedRecommendations([]));
  };

  const handleSendRecommendations = () =>
    sendRecommendationsMutation
      .mutateAsync(selectedRecommendations)
      .then(() => setSelectedRecommendations([]))
      .catch(() => setSelectedRecommendations([]));

  const handleEditInsurance = (id: string) => openInsuranceModal(id);

  const { essential: essentialInsurances = [], other: otherInsurances = [] } =
    data.insurances;

  const insurancesRatio = getEssentialInsurancesRatio(
    essentialInsurances,
    data
  );

  const missingEssentials = getMissingEssentials(
    essentialInsurances,
    data.essentialCategoryId
  );

  const missingOthers = getMissingInsurancesWithRecommendation(
    data.recommendations,
    data.essentialCategoryId,
    false
  );

  const missingEssentialsWithRecommendation =
    getMissingInsurancesWithRecommendation(
      data.recommendations,
      data.essentialCategoryId,
      true
    ).filter(includes(__, pluck('categoryId', essentialInsurances)));

  const getRecommendations = findRecommendationBy(data);

  interface RecommendationButtonModeProps {
    buttonMode: keyof typeof SelectMode;
    type?:
      | 'link'
      | 'text'
      | 'ghost'
      | 'default'
      | 'primary'
      | 'dashed'
      | undefined;
    onClick: () => void;
    danger?: boolean;
  }

  const RecommendationButtonMode = ({
    buttonMode,
    type,
    danger,
    onClick
  }: RecommendationButtonModeProps) => (
    <Button
      danger={
        (mode === SelectMode[buttonMode] && !selectedRecommendations.length) ||
        danger
      }
      disabled={
        !data.recommendations.length ||
        (mode && mode !== SelectMode[buttonMode])
      }
      type={type}
      onClick={() => {
        if (mode === SelectMode[buttonMode]) {
          if (selectedRecommendations.length) onClick();
          setMode(undefined);
        } else setMode(SelectMode[buttonMode]);
      }}
    >
      <CloseOutlined />
      {mode === SelectMode[buttonMode]
        ? selectedRecommendations.length
          ? `${capitalize(buttonMode)} recommendations`
          : 'Cancel select mode'
        : `Select to ${buttonMode}`}
    </Button>
  );

  const onRecommendationClick = (id: string) => {
    if (mode) {
      setSelectedRecommendations(
        ifElse(includes(id), reject(equals(id)), append(id)) as any
      );
    } else goToRecommendation(id);
  };

  const essentials = [
    ...missingEssentialsWithRecommendation.map((element) => ({
      categoryId: element,
      categoryName: CategoryLabelMap[element],
      type: 'missingEssentialsWithRecommendation'
    })),
    ...essentialInsurances.map((element) => ({
      ...element,
      categoryName: CategoryLabelMap[element.categoryId],
      type: 'essentialInsurances'
    })),
    ...missingEssentials.map((element) => ({
      categoryId: element,
      categoryName: CategoryLabelMap[element],
      type: 'missingEssentials'
    }))
  ].sort((a, b) =>
    a.categoryName.localeCompare(b.categoryName)
  ) as Essentials[];

  const others = [
    ...missingOthers.map((element) => ({
      categoryId: element,
      categoryName: CategoryLabelMap[element],
      type: 'missingOthers'
    })),
    ...otherInsurances.map((element) => ({
      ...element,
      categoryName: CategoryLabelMap[element.categoryId],
      type: 'otherInsurances'
    }))
  ].sort((a, b) => a.categoryName.localeCompare(b.categoryName)) as Others[];

  return (
    <div className="company-overview">
      <div className="company-overview-actions">
        <Button type="text" onClick={goBack}>
          <LeftCircleOutlined />
          Go back
        </Button>
        <Button
          size="large"
          type="primary"
          onClick={() => openLocationModal()}
          icon={<PlusCircleOutlined />}
        >
          New Location
        </Button>
      </div>
      <div className="company-overview-details">
        <div className="company-overview-details-header">
          <div className="user-details">
            <UserOutlined />
            <span>Company - {data.companyName}</span>
          </div>
          {companyLocations && companyLocations.length > 1 && (
            <Select
              style={{ minWidth: '200px' }}
              defaultValue={data.locationId}
              onChange={goToLocation}
            >
              {companyLocations.map(({ value, label }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          )}
        </div>
        <div className="company-name">
          <BuildOutlined />
          <span>{data.name}</span>
          <EditFilled onClick={() => openLocationModal(data.locationId)} />
          <Popconfirm
            title="Are you sure to delete this location?"
            onConfirm={handleDelete}
          >
            <DeleteFilled />
          </Popconfirm>
        </div>
        <div className="company-details">
          <div className="company-details-header">
            <div className="company-details-header-actions">
              <HistoryModal id={data.locationId} />
            </div>
          </div>
          <div className="company-details-info">
            <div className="info-element">
              <span className="label">Created At</span>{' '}
              {new Date(data.createdAt).toLocaleDateString()}
            </div>
            <div className="info-element">
              <span className="label">Location Address</span>{' '}
              {formatAssessmentAddress(data.address)}
            </div>
            <div className="info-element">
              <span className="label">Location Type</span> {locationTypeLabel}
            </div>
            <div className="info-element">
              <span className="label">Business Vertical</span>{' '}
              {locationTypesLoading
                ? 'Loading...'
                : locationTypesData?.getVerticalLabel(data.businessVertical) ??
                  'UNKNOWN!'}
            </div>
            <div className="info-element">
              <span className="label">Combined assessment</span>{' '}
              {isEmpty(combinedAssessment)
                ? 'Not started'
                : combinedAssessment?.complete
                  ? 'Completed'
                  : 'Started'}
              {combinedAssessment && (
                <AssesmentModal
                  id={combinedAssessment?.assessmentId}
                  categoryId="CL"
                />
              )}
            </div>
            <div className="info-element">
              <span className="label">Legal assessment</span>{' '}
              {isEmpty(legalAssessment)
                ? 'Not started'
                : legalAssessment?.complete
                  ? 'Completed'
                  : 'Started'}
              {legalAssessment && (
                <AssesmentModal
                  id={legalAssessment?.assessmentId}
                  categoryId="LE"
                />
              )}
            </div>
            <div className="info-element">
              <span className="label">Killer Question</span>{' '}
              <KillerQuestionModal />
            </div>
            <div className="info-element">
              <span className="label">Self assessment</span>{' '}
              {selfAssessment
                ? `${capitalize(
                    selfAssessment?.status
                  )} (last updated at ${new Date(
                    selfAssessment.updatedAt
                  ).toLocaleString()})`
                : ''}
              <SelfAssesmentModal />
            </div>
          </div>
        </div>
        <div className="company-users">
          <div className="company-users-header">
            Users ({data.users.length})
            <div className="company-users-header-actions">
              <Button type="link" onClick={() => openItemDialog()}>
                <PlusCircleOutlined />
                New User
              </Button>
            </div>
          </div>
          <div className="company-users-list">
            {data.users.map((user) => (
              <div className="company-user" key={user.auth0}>
                <div className="company-user-header">
                  <span className="company-user-name">
                    {getUserFullname(user)}
                  </span>
                  <div className="company-user-header-actions">
                    <EditFilled onClick={() => openItemDialog(user)} />
                    <DeleteFilled
                      onClick={() => handleRemoveUser(user.auth0)}
                    />
                  </div>
                </div>
                <div className="company-user-detail">
                  <div className="info-element">
                    <span className="label">Telephone</span> {user.phone}
                  </div>
                  <div className="info-element">
                    <span className="label">E-Mail</span> {user.email}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="company-locations">
          <div className="company-locations-header">
            Insurances (
            {essentialInsurances.length + otherInsurances.length || 0})
            <div className="company-locations-header-actions">
              <AddInsuranceModal disabled={Boolean(mode)} />
              <div
                className="company-locations-header-actions"
                style={{ border: '1px solid lightblue', borderRadius: '6px' }}
              >
                <AddRecommendation
                  disabled={Boolean(mode)}
                  locationBusinessVertical={data.businessVertical}
                />
                <RecommendationButtonMode
                  onClick={handleSendRecommendations}
                  buttonMode={SelectMode.send}
                  type="primary"
                />
                <RecommendationButtonMode
                  onClick={handleWithdrawRecommendations}
                  buttonMode={SelectMode.withdraw}
                />
                <RecommendationButtonMode
                  onClick={handleDeleteRecommendations}
                  buttonMode={SelectMode.delete}
                  type="primary"
                  danger
                />
              </div>
            </div>
          </div>
        </div>
        <div className="add-insurance-row insurance-container">
          <div
            className={`insurance-column ${
              otherInsurances.length ? 'add-insurance-form-item' : ''
            }`}
          >
            Essential{' '}
            <span
              className={`essentialRatio ${
                insurancesRatio.ratio < 0.5
                  ? 'essentialRatioDanger'
                  : insurancesRatio.ratio === 1
                    ? 'essentialRatioSafe'
                    : 'essentialRatioWarn'
              }`}
            >
              {insurancesRatio.text}
            </span>
            <Divider />
            <div className="insurance-card-container">
              {essentials.map(({ categoryId, ...rest }) => {
                if (rest.type === 'missingEssentialsWithRecommendation') {
                  return (
                    <InsuranceCard
                      key={categoryId}
                      categoryId={categoryId}
                      locationBusinessVertical={data.businessVertical}
                      selfAssessmentStatus={data?.selfAssessment}
                    >
                      {getRecommendations({
                        categoryId
                      }).map((data) => (
                        <RecommendationCard
                          onClick={onRecommendationClick}
                          selected={selectedRecommendations.includes(
                            data.recommendationId
                          )}
                          mode={mode}
                          {...data}
                          key={data.recommendationId}
                        />
                      ))}
                    </InsuranceCard>
                  );
                } else if (rest.type === 'essentialInsurances') {
                  return (
                    <BoughtInsuranceCard
                      key={rest.insuranceId}
                      onEdit={handleEditInsurance}
                      categoryId={categoryId}
                      locationBusinessVertical={data.businessVertical}
                      name={rest.name}
                      carrierName={rest.carrierName}
                      logoUrl={rest.logoUrl}
                      contractStatus={rest.contractStatus}
                      contractType={rest.contractType}
                      policyNumber={rest.policyNumber}
                      insuranceId={rest.insuranceId}
                      insuranceLabel={rest.insuranceLabel}
                      consultationProtocol={rest.consultationProtocol}
                      risksInsured={rest.risksInsured}
                      mainRiskInsured={rest.mainRiskInsured}
                      allRisksInsured={rest.allRisksInsured}
                      insuredRiskOtherSpecification={
                        rest.insuredRiskOtherSpecification
                      }
                      locationData={data}
                      selfAssessmentStatus={data?.selfAssessment}
                      createdAt={rest.createdAt}
                      sourceRecommendation={rest.sourceRecommendationId}
                    >
                      {getRecommendations({
                        insuranceId: rest.insuranceId
                      }).map((data) => (
                        <RecommendationCard
                          onClick={onRecommendationClick}
                          selected={selectedRecommendations.includes(
                            data.recommendationId
                          )}
                          mode={mode}
                          {...data}
                          key={data.recommendationId}
                        />
                      ))}
                    </BoughtInsuranceCard>
                  );
                } else if (rest.type === 'missingEssentials') {
                  return (
                    <InsuranceCard
                      key={categoryId}
                      categoryId={categoryId}
                      locationBusinessVertical={data.businessVertical}
                      selfAssessmentStatus={data?.selfAssessment}
                    >
                      {getRecommendations({
                        categoryId
                      }).map((data) => (
                        <RecommendationCard
                          onClick={onRecommendationClick}
                          selected={selectedRecommendations.includes(
                            data.recommendationId
                          )}
                          mode={mode}
                          {...data}
                          key={data.recommendationId}
                        />
                      ))}
                    </InsuranceCard>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
          {Boolean(otherInsurances.length || missingOthers.length) && (
            <div className="add-insurance-form-item insurance-column">
              Other ({otherInsurances.length})
              <Divider />
              <div className="insurance-card-container">
                {others.map(({ categoryId, ...rest }) => {
                  if (rest.type === 'otherInsurances') {
                    return (
                      <BoughtInsuranceCard
                        consultationProtocol={rest.consultationProtocol}
                        key={rest.insuranceId}
                        onEdit={handleEditInsurance}
                        categoryId={categoryId}
                        locationBusinessVertical={data.businessVertical}
                        name={rest.name}
                        carrierName={rest.carrierName}
                        logoUrl={rest.logoUrl}
                        contractStatus={rest.contractStatus}
                        contractType={rest.contractType}
                        policyNumber={rest.policyNumber}
                        insuranceId={rest.insuranceId}
                        insuranceLabel={rest.insuranceLabel}
                        risksInsured={rest.risksInsured}
                        mainRiskInsured={rest.mainRiskInsured}
                        allRisksInsured={rest.allRisksInsured}
                        selfAssessmentStatus={data?.selfAssessment}
                        createdAt={rest.createdAt}
                        sourceRecommendation={rest.sourceRecommendationId}
                        locationData={data}
                      >
                        {getRecommendations({
                          insuranceId: rest.insuranceId
                        }).map((data) => (
                          <RecommendationCard
                            onClick={onRecommendationClick}
                            selected={selectedRecommendations.includes(
                              data.recommendationId
                            )}
                            mode={mode}
                            {...data}
                            key={data.recommendationId}
                          />
                        ))}
                      </BoughtInsuranceCard>
                    );
                  } else if (rest.type === 'missingOthers') {
                    return (
                      <InsuranceCard
                        key={categoryId}
                        categoryId={categoryId}
                        locationBusinessVertical={data.businessVertical}
                        selfAssessmentStatus={data?.selfAssessment}
                      >
                        {getRecommendations({
                          categoryId
                        }).map((data) => (
                          <RecommendationCard
                            onClick={onRecommendationClick}
                            selected={selectedRecommendations.includes(
                              data.recommendationId
                            )}
                            mode={mode}
                            {...data}
                            key={data.recommendationId}
                          />
                        ))}
                      </InsuranceCard>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      {isLocationModalOpened && selectedLocationId && data && (
        <LocationModal
          data={data}
          visible={isLocationModalOpened}
          onClose={closeLocationModal}
          onAdd={goToLocation}
        />
      )}
      {isInsuranceModalOpened && selectedInsuranceId && (
        <EditInsuranceModal
          id={selectedInsuranceId}
          visible={isInsuranceModalOpened}
          onClose={closeInsuranceModal}
        />
      )}
      {isOpen && (
        <CompanyUserModal
          id={selectedUser?.auth0}
          locationId={data.locationId}
          visible={isOpen}
          onClose={closeItemDialog}
          hide={{ birthdate: true }}
        />
      )}
    </div>
  );
};

interface RecommendationCardProps {
  recommendationId: string;
  sended: string | null;
  lastUpdatedBy: string | null;
  bestChoice: string | null;
  onClick: (id: string) => void;
  selected: boolean;
  mode: keyof typeof SelectMode | undefined;
}

const RecommendationCard = ({
  recommendationId,
  sended,
  lastUpdatedBy,
  bestChoice,
  onClick,
  selected,
  mode
}: RecommendationCardProps) => {
  const cannotSend =
    mode && mode === SelectMode.send && (sended || !bestChoice);

  const cannotWithdraw = mode && mode === SelectMode.withdraw && !sended;

  return (
    <Tooltip
      title={
        cannotSend
          ? sended
            ? 'Already sent'
            : 'No best choice selected'
          : cannotWithdraw && 'You can only withdraw sent recommendations'
      }
    >
      <div
        onClick={() => {
          if (cannotSend) return;
          onClick(recommendationId);
        }}
        className={`recommendation-card ${
          cannotSend || cannotWithdraw ? 'recommendation-card-disabled' : ''
        } ${
          sended
            ? 'recommendation-card-sent'
            : lastUpdatedBy === 'user'
              ? bestChoice
                ? 'recommendation-card-rejected'
                : 'recommendation-card-waiting'
              : ''
        }`}
      >
        {mode &&
          selected &&
          (mode === SelectMode.send ? (
            <CheckCircleOutlined />
          ) : (
            <CloseCircleOutlined />
          ))}
        {lastUpdatedBy === 'user' && !bestChoice
          ? 'User request'
          : 'Recommendation'}
        <div>
          {' '}
          <i>Status</i>{' '}
          <span>
            {sended
              ? 'Sent'
              : lastUpdatedBy === 'user'
                ? bestChoice
                  ? 'Rejected'
                  : 'Waiting for admin'
                : bestChoice
                  ? 'Ready to send'
                  : 'Not sent'}
          </span>
        </div>
        {sended && (
          <>
            <i>Sent date</i> <span>{timestampToDateString(sended)}</span>
          </>
        )}
      </div>
    </Tooltip>
  );
};
