import React, { useEffect } from 'react';

import {
  RecommendationProduct,
  RiskAddress,
  SearchCriteria
} from '@/types/recommendation';
import { Form } from 'antd';
import { mergeAll, pick } from 'ramda';
import { RecommendationProductForm } from './RecommendationProductForm';
import { InsuranceApiResult, InsuranceProduct } from '@/types/insurance';
import { BusinessVerticalWithDefault } from '@/enums/businessVertical';

const makeInitialValues = (products: RecommendationProduct[]) =>
  mergeAll(
    products.map(
      (
        {
          insuranceProductId,
          startDate,
          amountInsured,
          amountInsuredUnlimited,
          deductible,
          grossPrice,
          netPrice,
          paymentPeriod,
          commission,
          highlightFields,
          highlightText = [],
          parametersValues,
          yesNoValues,
          offerTag,
          discountValue,
          risksInsured,
          lossOfEarnings,
          agreementNumber,
          options
        },
        i
      ) => ({
        [`insuranceProductId_${i}`]: insuranceProductId,
        [`startDate_${i}`]: startDate,
        [`amountInsured_${i}`]: amountInsured,
        [`amountInsuredUnlimited_${i}`]: amountInsuredUnlimited,
        [`deductible_${i}`]: deductible,
        [`grossPrice_${i}`]: grossPrice,
        [`netPrice_${i}`]: netPrice,
        [`paymentPeriod_${i}`]: paymentPeriod,
        [`commission_${i}`]: commission,
        [`highlightFields_${i}`]: highlightFields,
        [`highlightText_${i}`]: highlightText.join(' '),
        [`parametersValues_${i}`]: parametersValues,
        [`yesNoValues_${i}`]: yesNoValues,
        [`offerTag_${i}`]: offerTag,
        [`discountValue_${i}`]: discountValue,
        [`risksInsured_${i}`]: risksInsured,
        [`lossOfEarnings_${i}`]: lossOfEarnings,
        [`agreementNumber_${i}`]: agreementNumber,
        [`options_${i}`]: options
      })
    )
  );

interface PageFormProps {
  locationId: string;
  companyId: string | null;
  categoryId: string;
  businessVertical: BusinessVerticalWithDefault;
  recommendationProducts: RecommendationProduct[];
  insurance: InsuranceApiResult | undefined;
  onSearch: () => void;
  visibleAttributes: string[];
  /**
   * TODO: convertedInsuranceId is probably obsolete now we have insurance
   */
  convertedInsuranceId: string | null;
  searchCriteria: SearchCriteria | null;
  products?: Partial<InsuranceProduct>[];
  openSearchCriteria: () => void;
  locationType: string | null;
  locationSubtypes: string[];
  riskAddress: RiskAddress;
}

export const PageForm = ({
  locationId,
  companyId,
  categoryId,
  businessVertical,
  recommendationProducts,
  insurance,
  onSearch,
  visibleAttributes,
  convertedInsuranceId,
  searchCriteria,
  products = [],
  openSearchCriteria,
  locationType,
  locationSubtypes,
  riskAddress
}: PageFormProps) => {
  const [form] = Form.useForm();

  const makeProductDependencies = (items: RecommendationProduct[]) => {
    return items.map(
      pick([
        'insuranceProductId',
        'grossPrice',
        'netPrice',
        'startDate',
        'offerTag',
        'options'
      ])
    );
  };

  const formDependencies = JSON.stringify([
    ...makeProductDependencies(recommendationProducts),
    searchCriteria
  ]);

  useEffect(() => {
    form.setFieldsValue(makeInitialValues(recommendationProducts));
    form.resetFields();
  }, [formDependencies]);

  return (
    <Form
      scrollToFirstError
      initialValues={makeInitialValues(recommendationProducts)}
      form={form}
    >
      <RecommendationProductForm
        products={products}
        locationId={locationId}
        categoryId={categoryId}
        businessVertical={businessVertical}
        companyId={companyId}
        searchCriteria={searchCriteria}
        recommendationProducts={recommendationProducts}
        convertedInsuranceId={convertedInsuranceId}
        openSearchCriteria={openSearchCriteria}
        locationType={locationType}
        locationSubtypes={locationSubtypes}
        riskAddress={riskAddress}
        visibleAttributes={visibleAttributes}
        onSearch={onSearch}
        insurance={insurance}
      />
    </Form>
  );
};
