import React from 'react';

import { Header } from '@/components';
import { PageLayout } from '@/components/PageLayout';
import { mapWithKey } from '@/utils';
import { Switch } from 'react-router-dom';
import { AuthGuard } from './AuthGuard';
import { routes } from './routes';
import { ScrollToTop } from './ScrollToTop';
import { UnauthorizedGuard } from './UnauthorizedGuard';
import { paths } from '@/routes/paths';
import { ProductTemplateItem } from '@/pages/ProductTemplateItem';

export const BaseRouter = () => {
  return (
    <>
      <ScrollToTop />
      <UnauthorizedGuard />
      <Header />

      <Switch>
        <PageLayout>{mapWithKey(AuthGuard, 'path', routes)}</PageLayout>
      </Switch>

      {/* Modal switch */}
      <Switch>
        <AuthGuard
          path={paths.productTemplatesItem}
          component={ProductTemplateItem}
        />
      </Switch>
    </>
  );
};
