interface VerticalTemplate {
  id: string;
  name: string;
  type: 'vertical';
}

interface CategoryTemplate {
  id: string;
  name: string;
  type: 'category';
  children?: VerticalTemplate[];
}

export type ProductTemplatesApiResponse = CategoryTemplate[];

const data: ProductTemplatesApiResponse = [
  {
    id: '1',
    name: 'Liability insurance',
    type: 'category',
    children: [
      {
        id: '11',
        name: 'Gastronomy',
        type: 'vertical'
      },
      {
        id: '12',
        name: 'Craftwork',
        type: 'vertical'
      },
      {
        id: '13',
        name: 'Construction',
        type: 'vertical'
      }
    ]
  },
  {
    id: '2',
    name: 'Contents insurance',
    type: 'category'
  }
];

export interface ProductTemplateItemApiResponse {
  id: string;
  type: 'vertical' | 'category';
  tax: number;
  attributes: {
    code: string;
    name: string;
  }[];
}

export const listProductTemplates = (): Promise<ProductTemplatesApiResponse> =>
  Promise.resolve(data);

export const getProductTemplateById = (
  id: string
): Promise<ProductTemplateItemApiResponse> =>
  Promise.resolve({
    id,
    type: 'vertical',
    tax: 19,
    attributes: [
      {
        code: 'foo_attributes',
        name: 'Attribute 1'
      },
      {
        code: 'bar_attribute',
        name: 'Attribute 2'
      }
    ]
  });
